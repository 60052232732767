html,
body {
  margin: 0px;
  background-color: #fff;
  
}

.animate {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.one {
-webkit-animation-delay: 0.5s;
-moz-animation-delay: 0.5s;
animation-delay: 0.5s;
}

.two {
-webkit-animation-delay: 1s;
-moz-animation-delay: 1s;
animation-delay: 1s;
}

/*==== FADE IN UP ===*/
@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

.homeBanner{
	background-image: url("./images/Java8Img.png");
	height: 300px;
	background-size: cover;
}

.bg-body{
    background-color: #ecf4fc;
    padding-bottom: 20px;
}

.btn-primary {
    background-color: #6f42c1;
    border: #6f42c1;
}

.btn-primary:hover {
    background-color: #ecf4fc;
    border: 1px solid #6f42c1;
    color:#6f42c1;
}

.btn-custom {
    color: #6f42c1;
    background-color: #ecf4fc;
    border-top: 0.1rem solid #6f42c1;
    border-radius: 0rem 0rem .5rem .3rem;
}

.btn-custom:hover {
    color: white;
    background-color: #6f42c1;
    border: 0.05rem solid #6f42c1;
}

hr {
    border-top: 2px solid #6f42c1;
    margin-top: -.2rem;
}

.paddingTop5 {
    padding-top: 5px;
}

.paddingTop10 {
    padding-top: 10px;
}

.paddingTop20 {
    padding-top: 20px;
}

.paddingTop25 {
    padding-top: 25px;
}

.paddingTop30 {
    padding-top: 30px;
}

.paddingTop65 {
    padding-top: 65px;
}

.paddingLeft10 {
    padding-left: 10px;
}

.paddingLeft20 {
    padding-left: 20px;
}

.paddingBottom10 {
    padding-bottom: 10px;
}

.paddingBottom20 {
    padding-bottom: 20px;
}

.paddingBottom40 {
    padding-bottom: 40px;
}

/*navbar customization*/

.navbar-bg {
    background-color: #6f42c1;
}

.navbar-brand {
    font-size: 18px;
    padding-top: 0px;
    padding-bottom: 0px;
}

.navbar-nav {
    font-size: 13px;
}

.navbar-link {
    font-size: 13px;
}

.bg-primary {
    background-color: #6f42c1;
}

.navbar-brand {
	text-shadow: 3px 3px 3px #ecf4fc;
}

.redColor {
	color: red;
	font-weight: bolder;
}

.blackColor {
	color: black;
	font-weight: bolder;
}

.navbar-light .navbar-nav .nav-link {
    color: #6f42c1;
}

.navbar-light .navbar-nav .active>.nav-link, .navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .nav-link.show, .navbar-light .navbar-nav .show>.nav-link {
    color: #6f42c1;
    background-color: #ecf4fc;
}

.navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover {
    background-color: white;
    color: #6f42c1;
}

.navbar {
    padding: 6px;
    font-weight: bold;
    background-color: #ecf4fc;
    border-bottom: solid 2px #6f42c1;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.5);
    
}

.navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 1.0rem;
    padding-left: 1.0rem;
}

.dropdown-menu {
	background-color:#ecf4fc;
    white-space: nowrap;
    padding: .0rem 0;
    margin: 0px;
    line-height: 1.8;
    font-size: 12px;
}

.dropdown-item {
	color: white;
	padding: 0px;
}

.dropdown-item:focus, .dropdown-item:hover {
    background-color: #ecf4fc;
    color: #6f42c1;
}

.dropdown-item .nav-link:focus, .dropdown-item .nav-link:hover {
    background-color: #ecf4fc;
    color: #6f42c1;
    border-top: 1px dotted #6f42c1;
    border-bottom: 1px dotted #6f42c1;
}

.dropdown-item .nav-link {
	padding: 1px;
}

.dropdown-submenu {
  position: relative;
}

.dropdown-submenu a {
    white-space: nowrap;
}

.dropdown-submenu a::after {
  transform: rotate(-90deg);
  position: absolute;
  right: 1px;
  top: 1.1em;
}

.dropdown-submenu .dropdown-menu {
  top: 0;
  left: 100%;
  margin-right: .1rem;
}

.dropdown-submenu .nav-link {
    margin-right:0px;

}

ol, ul {
  padding-left: 30px;
}

.card {  
  border: 1px solid white;  
  border-radius: .5rem;
}

.homeCard {
  align-items: center;	
  border: 1px solid #6f42c1;
  border-left: 5px solid #6f42c1;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.5);
}

.cardShadow {
  border: 1px solid #6f42c1;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.5);
}

.spanCard {
  color: #6f42c1;		
  background-color: #ecf4fc;	
  padding-top: 10px;
  margin: 0px;	
}

.spanCardWhitesmoke {
  background-color: whitesmoke;	
  border-radius: 0.5rem;
  padding: 10px;
  margin: 0px;	
}

.courseCard {
  align-items: center;	
  border: .5px solid #6f42c1;
  transform: scale(.99);
  transition: 0.2s all cubic-bezier(0.175, 0.885, 0.32, 1.275);
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.5);
  
}

.courseContentCard {	
  border: 1px solid #6f42c1;
  border-left: 5px solid #6f42c1;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.5);
}

.card-body {
	padding: 10px;	
	margin: 0px;
}

.homeCard:hover {
  transform: scale(.99);
  transition: 0.2s all cubic-bezier(0.175, 0.885, 0.32, 1.275);
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.5);
  border: 1px solid #6f42c1;
  border-left: 5px solid #6f42c1;
}

.homeCard:hover:before {
  opacity: 1;
}

.cardImage {
	border-radius: .2rem .4rem 0rem 0rem;	
}

.nav-link {
	color: #6f42c1;
}
.navbar-nav .nav-item .nav-link {
	font-size: large;
}

.flex-column .nav-item .nav-link:hover {
    color: #6f42c1;
    background-color: #ecf4fc;
    border: 1px dotted #6f42c1;
    border-radius: 2px 30px 30px 2px;
}

.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
    color: #6f42c1;
    background-color: #ecf4fc;
    border-radius: 2px 30px 30px 2px;
    
}

.header {
	text-shadow: 3px 3px 3px #ababab;
}

.tab-content {
	border-top: 1px solid #6f42c1;
	padding-top: 10px;
	margin-top: 10px;
	margin-bottom: 10px;
}

.board-row:after {
  clear: both;
  content: "";
  display: table;
}

.status {
  margin-bottom: 10px;
}

.menuItems {
	box-shadow: 0 0 31px #efefef;
	border-radius: 5px 30px 30px 5px;
}

.note {
  background: #ecf4fc;
  border: 1px solid #f00;
  border-left: 5px solid #f00;
  border-radius: 5px 5px 5px 5px;
  padding: 10px;
}

.noteInfo {
  background: #ecf4fc;
  border: 1px solid #6f42c1;
  border-left: 5px solid #6f42c1;
  border-radius: 5px 5px 5px 5px;
  padding: 10px;
}

.pCenter {
	text-align: center;
}

.pCode {
	background: #ecf4fc;
	border: 1px solid #6f42c1;
	border-left: 5px solid #6f42c1;
	border-radius: 5px 5px 5px 5px;	
	padding-left: 10px;
}

.codeBlock {
	color : green;
	text-align: center;
	font-weight: 500;
}

.pComment {
	color: limegreen;
	font-style: italic;
}

.pullRight {
  margin-left: 1080px;
}

.height {
    height: 1000px;
}

.divScroll {
    overflow-x: auto;
    max-height: 500px;
    overflow-y: auto;
}

.tableScrollBody {
    cursor: pointer;
}

.tableScroll {
    margin-bottom: 0px;
}

.hidden {
    display:none;
}

.textColorRed {
    color: red;
}

.textColorGreen {
    color: green;
}

.textColorDarkGreen {
    color: darkgreen;
}

.textColorPink {
    color: #6f42c1;
}

.textColorBg {
    color: #ecf4fc;
}

.tableCellDiv {
    border-top : 1px solid #D3D3D3;
    padding-left: 5px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.aModule {
	color: black;
	text-decoration: none;
}

.aModule:hover {
	color: #6f42c1;
}

.courseContentDiv {
	border-bottom: 1px solid #6f42c1;
    margin-bottom: -.2rem;
}

.dropDownDividerSpan {
	color: #6f42c1;
    padding-left: 5px;
    font-size: medium;
    font-weight: 500;
}

.hrDivider {
	margin-bottom: 0px;
	border-top: 0px;
}

.myFooter {
  	width: 100%;
	background-color: #ecf4fc;
    text-align: center;
    color: #6f42c1;
    border-top: 7px solid #6f42c1;
    box-shadow: 0px 0px 20px 5px rgba(0, 0, 0, 0.5);
    margin-top: 50px;
    padding-top: 20px;
    padding-bottom: 20px;
}

.aFooter{
	color: #6f42c1;
    text-decoration: none;
    padding-top: 5px;
}

.aFooter:hover {
	color: blue;
}

.paddingTop150 {
	padding-top: 150px;
}


.bannerHeading {
	background-color: #6f42c1;
	color: white;
	text-align: center;
}

.aBanner{
	color: red;
	font-weight: bold;
    text-decoration: dotted;
}

ul.nav {
  width: 100%;
  margin: 0 auto 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #6f42c1;
  border-radius: 2rem;
  padding: 0px;
  @media (max-width: 768px) {
    width: 90%;
  }
}
ul.nav li {
  width: 50%;
  padding: .3rem;
  list-style: none;
  text-align: center;
  cursor: pointer;
  color: #6f42c1;
  font-weight: bold;
  transition: all 0.7s;
  border-bottom-left-radius: 2rem;
  border-top-left-radius: 2rem;
}
ul.nav li:nth-child(2) {
  border-radius: 0;
  border-bottom-right-radius: 2rem;
  border-top-right-radius: 2rem;
}
ul.nav li:hover {
  background: rgba(50, 224, 196, 0.15);
}
ul.nav li.active {
  background: #6f42c1;
  color: white;
  font-weight: bold;
}

.priceTag {
	rotate: -20deg;
	background-color: #ecf4fc;
    font-weight: 400;
    border-radius: 5%;
   	padding: 5px;
}

.oldPriceTag {
	color: red;
    font-weight: 200;
    text-decoration: line-through;
   
}

.offerPriceTag {
    color: #6f42c1;
    font-weight: 900;
 
}